import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Divider, FormHelperText, Stack, ThemeProvider, Typography } from '@mui/material';
import { ArrowLeftIcon, LinkSvg } from 'assets/svgs/componentsIcons';
import { BackButton } from 'components/Buttons';
import ActionButton from 'components/ActionButton';
import AutocompleteInput from 'components/AutocompleteInput';
import {
  PROJECT_SETTINGS_EDIT_PROJECT,
  PROJECT_SETTINGS_EDIT_PROJECT_WORK_DIMENSIONS,
  SETTINGS_IN_PROCESS_PROJECTS,
  SETTINGS_PROJECTS,
} from 'routes/constants';
import { useLazyGetUsersByFullNameQuery } from 'services/apis/user';
import { useGetRolesByCategoriesQuery } from 'services/apis/role';
import {
  useLazyGetUserProjectByProjectIdAndPlatformQuery,
  useReassignUserToProjectMutation,
} from 'services/apis/user-project';
import {
  assignUsersToProject,
  resetProjectMembers,
  setPlatforms,
  setProfiles,
  setProjectUsers,
  setSelectedRole,
  setSelectedUser,
  setSelectProfiles,
  setUserProjectsByCategory,
} from 'services/slices/project-setting/project-members';
import { arrayToMap, resetInMap, setInMap } from 'utils/ArrayUtils';
import AppTheme from 'ui/theme/app-theme';
import { useLazyGetProfilesByPlatformAndProjectQuery } from 'services/apis/access-control/profile';
import { useLazyGetPlatformsByProjectIdQuery } from 'services/apis/platform';
import ConfirmationPopup from 'components/ConfirmationPopin';
import TabTitles from 'components/StyledComponents/TabTitles';
import { POPUP_TYPE } from 'components/ConfirmationPopin/constants';
import FullPageLoader from 'components/FullPageLoader';
import ProjectSettingsFooter from 'pages/AppProjectsSettings/ProjectSettingsFooter';
import ProjectCreationPopup from 'containers/ProjectCreationPopup';
import { makeSelectProjectCreationStatus } from 'pages/AppProjectsSettings/ProjectsSettingsProjectCreation/selectors';
import { PROJECT_CREATION_STATUS } from 'helpers/constants';
import { setProjectCreationStatus } from 'services/slices/project-setting/project-creation';
import { useLazyGetProjectCreationStatusByIdProjectQuery } from 'services/apis/project';
import usePrivilegeByFeature from 'utils/hooks/usePrivilegeByFeature';
import { Privileges } from 'utils/constants/Features';
import ProjectsSettingsProjectMembersItem from './ProjectsSettingsProjectMembersItem';
import {
  PLATFORM_FEATURE,
  PLATFORM_WITHOUT_PLATFORM,
  TAB_FILTER_NAME,
} from './constants';
import {
  makeSelectPlatforms,
  makeSelectProfiles,
  makeSelectProjectUsers,
  makeSelectSelectedProfiles,
  makeSelectSelectedRole,
  makeSelectSelectedUser,
  makeSelectUserProjectsByCategory,
} from './selectors';
import ProjectsSettingsProjectMembersWrapper from './Wrapper';

const stateSelector = createStructuredSelector({
  projectUsers: makeSelectProjectUsers,
  userProjectsByCategory: makeSelectUserProjectsByCategory,
  selectedUser: makeSelectSelectedUser,
  selectedRole: makeSelectSelectedRole,
  selectedProfiles: makeSelectSelectedProfiles,
  profiles: makeSelectProfiles,
  platforms: makeSelectPlatforms,
  projectCreationStatus: makeSelectProjectCreationStatus,
});

function ProjectsSettingsProjectMembers() {
  const {
    projectUsers,
    userProjectsByCategory,
    selectedUser,
    selectedRole,
    selectedProfiles,
    profiles,
    platforms,
    projectCreationStatus,
  } = useSelector(stateSelector);

  const navigate = useNavigate();
  const { projectId } = useParams();
  const [selected, setSelected] = useState(TAB_FILTER_NAME.ALL.code);
  const [fullName, setFullName] = useState('');
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [roleName, setRoleName] = useState('');
  const [profileName, setProfileName] = useState('');
  const [dataUpdated, setDataUpdated] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [associate, setAssociate] = useState(false);
  const [confirmationBackPopup, setConfirmationBackPopup] = useState(false);
  const [openDraftModal, setOpenDraftModal] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [isSelectedUser, setIsSelectedUsed] = useState(false);
  const [nullMailErrorMessage, setNullMailErrorMessage] = useState(null);
  const [isDraft, setIsDraft] = useState(false);

  useEffect(() => {}, [dataUpdated]);
  const roleCategories = useMemo(() => {
    if (selectedUser?.role?.category) {
      return selectedUser?.role?.category;
    }
    if (selectedUser?.externalRole !== null) {
      return selectedUser?.externalRole?.code;
    }
    if (selectedUser?.external) {
      return [
        TAB_FILTER_NAME.EXTERNAL_CLIENT.code,
        TAB_FILTER_NAME.EXTERNAL_CONTRACTOR.code,
      ];
    }
    return TAB_FILTER_NAME.JESA.code;
  }, [selectedUser]);

  const [doGetUsersByFullName, { data: users = [] }] = useLazyGetUsersByFullNameQuery();
  const { data: roles, isFetching: rolesLoading } = useGetRolesByCategoriesQuery(
    roleCategories,
    {
      skip: !roleCategories,
    },
  );
  const [doGetPlatforms] = useLazyGetPlatformsByProjectIdQuery();
  const [doGetProfileByPlatform, { isFetching: isloadingProfiles }] =
    useLazyGetProfilesByPlatformAndProjectQuery();
  const [doGetUserProjects, { isFetching: filterLoading }] =
    useLazyGetUserProjectByProjectIdAndPlatformQuery();
  const [
    doReassignUsersToProject,
    { isSuccess: creationSuccess, isLoading: creationLoading },
  ] = useReassignUserToProjectMutation();
  const [doGetProjectCreationStatusByIdProject] =
    useLazyGetProjectCreationStatusByIdProjectQuery();
  const [saveLeave, setSaveLeave] = useState(false);
  const [platform, setPlatform] = useState(null);
  const [tempPlatform, setTempPlatform] = useState('');
  const [openPlatformModal, setOpenPlatformModal] = useState(false);
  const [isNavigation, setIsNavigation] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (fullName?.length > 3) {
      doGetUsersByFullName(fullName);
    }
  }, [fullName, doGetUsersByFullName]);
  useEffect(() => {
    if (roleName?.length > 0) {
      const selectedRoles = roles?.filter((r) =>
        r.label?.toLowerCase().includes(roleName?.toLowerCase()),
      );
      setFilteredRoles(selectedRoles);
    }
  }, [roleName]);
  useEffect(() => {
    if (profileName?.length > 0) {
      const chosenProfiles = profiles?.filter((r) =>
        r.name?.toLowerCase().includes(profileName?.toLowerCase()),
      );
      setFilteredProfiles(chosenProfiles);
    }
  }, [profileName]);
  useEffect(() => {
    if (platform && roleCategories?.length > 0) {
      doGetProfileByPlatform({
        categories: roleCategories,
        projectId,
        platform,
      }).then(({ data }) => {
        dispatch(setProfiles(data));
      });
    }
  }, [platform, roleCategories]);

  useEffect(() => {
    if (projectUsers?.length !== 0) {
      let pUsers;
      if (selected === TAB_FILTER_NAME.ALL.code) {
        pUsers = Array.from(projectUsers.values()).flat();
      } else {
        pUsers = [];
        if (projectUsers.get(selected) !== undefined) {
          if (Array.isArray(projectUsers.get(selected))) {
            pUsers = [...projectUsers.get(selected)];
          } else {
            pUsers.push(projectUsers.get(selected));
          }
        }
      }
      dispatch(setUserProjectsByCategory(pUsers));
    }
  }, [selected, projectUsers, dispatch]);

  useEffect(() => {
    if (projectId) {
      doGetProjectCreationStatusByIdProject(projectId).then(({ data }) =>
        dispatch(setProjectCreationStatus(data)),
      );
      if (platform) {
        doGetUserProjects({ projectId, platform })
          .unwrap()
          .then((result) => {
            const unique = [...new Set((result || []).map((d) => d?.role?.category))];
            const userProjects = arrayToMap(result, unique);
            dispatch(setProjectUsers(userProjects));
          });
      }
      doGetPlatforms(projectId)
        .unwrap()
        .then((result) => {
          dispatch(
            setPlatforms(
              result.filter((item) => !PLATFORM_WITHOUT_PLATFORM.includes(item.code)),
            ),
          );
        });
    }
  }, [dispatch, projectId, platform]);
  const assignUserToProject = () => {
    let profilesToAffect = selectedProfiles || [];
    if (!selectedProfiles.find((profile) => profile?.platform?.code === platform)) {
      const defaultProfile = profiles?.find(
        (item) => item.code === selectedRole?.profileCode,
      );
      profilesToAffect = [...selectedProfiles, defaultProfile];
    }

    const userProject = {
      project: {
        id: projectId,
      },
      role: selectedRole,
      user: selectedUser,
      profiles: profilesToAffect,
      deleted: false,
    };
    let newProjectUsers;
    if (
      projectUsers
        .get(selectedRole?.category)
        ?.find((up) => up.user.id === userProject.user.id)
    ) {
      newProjectUsers = resetInMap(projectUsers, userProject, selectedRole?.category);
    } else {
      newProjectUsers = setInMap(projectUsers, userProject, selectedRole?.category);
    }

    return newProjectUsers;
  };
  const generateReassignDto = () => {
    const pUsers = Array.from(projectUsers.values()).flat();
    return pUsers.map((pu) => ({
      projectId: parseInt(projectId, 10),
      userId: pu.user.id,
      roleId: pu.role.id,
      profiles: pu.profiles,
      deleted: pu.deleted,
    }));
  };
  const updateUserProject = (userProject) => {
    return resetInMap(projectUsers, userProject, userProject?.role?.category);
  };

  const onDelete = (k) => {
    dispatch(assignUsersToProject(updateUserProject(k)));
    setDataUpdated(true);
  };
  const onclick = (elm) => {
    dispatch(setSelectedUser(elm));
    setFullName(elm.fullName);
    setIsSelectedUsed(true);
  };

  const navigateToProjectSettings = () => {
    navigate(
      projectCreationStatus === PROJECT_CREATION_STATUS.DRAFT
        ? SETTINGS_IN_PROCESS_PROJECTS
        : SETTINGS_PROJECTS,
    );
  };
  const onCancelClick = () => {
    setShowCancelPopup(false);
    dispatch(resetProjectMembers());
    navigateToProjectSettings();
  };

  const onCancelSuccess = () => {
    setShowSuccessPopup(false);
    dispatch(resetProjectMembers());
    navigateToProjectSettings();
  };

  const onClickCreateUpdate = () => {
    doReassignUsersToProject({
      body: generateReassignDto(),
      projectId: parseInt(projectId, 10),
      platform,
    });
    dispatch(resetProjectMembers());
  };
  useEffect(() => {
    if (selectedProfiles.length === 0 && selectedRole?.profileCode) {
      const defaultsProfiles = profiles?.filter(
        (item) => item.code === selectedRole.profileCode,
      );
      dispatch(setSelectProfiles(defaultsProfiles));
      setProfileName(
        profiles?.find((item) => item?.code === selectedRole?.profileCode)?.name,
      );
    }
  }, [platforms, selectedRole]);
  useEffect(() => {
    if (profiles) {
      setFilteredProfiles(profiles);
    }
  }, [profiles]);
  useEffect(() => {
    if (platforms && !platform) {
      setPlatform(platforms[0]?.code);
    }
  }, [platforms]);

  const selectedProfile = (profile) => {
    const filteredListProfiles = selectedProfiles.filter(
      (item) => item?.platform?.id !== profile?.platform?.id,
    );
    dispatch(setSelectProfiles([...filteredListProfiles, profile]));
  };

  useEffect(() => {
    if (!selectedUser || !projectUsers || !roles || !profiles || !roleCategories) {
      return;
    }
    const matchedUserProject = Array.from(projectUsers.values())
      .flat()
      .find((pu) => pu.user.id === selectedUser?.id);

    if (matchedUserProject) {
      const matchedRole = roles?.find((item) => item.id === matchedUserProject.role.id);
      if (matchedRole) {
        dispatch(setSelectedRole(matchedRole));
        setRoleName(matchedRole?.label);
      }
      const matchedProfiles = profiles?.filter((item) =>
        matchedUserProject.profiles.some((profile) => profile.id === item.id),
      );
      if (matchedProfiles?.length > 0) {
        dispatch(setSelectProfiles(matchedProfiles));
        setProfileName(matchedProfiles[0]?.name);
      }
    }
  }, [selectedUser, profiles, roles, projectUsers, roleCategories]);

  const cleanUser = () => {
    setFullName('');
    setRoleName('');
    setProfileName('');
    setIsSelectedUsed(false);
    setFilteredProfiles([]);
    setFilteredRoles([]);
    dispatch(setSelectedRole({}));
    dispatch(setSelectedUser({}));
    dispatch(setSelectProfiles([]));
  };
  const handleAssociateMember = () => {
    if (Object.keys(selectedRole)?.length > 0 && fullName) {
      dispatch(assignUsersToProject(assignUserToProject()));
      setAssociate(false);
      setDataUpdated(true);
      cleanUser();
    } else {
      setAssociate(true);
    }
  };
  const navigateToProjectCreation = () => {
    dispatch(resetProjectMembers());
    cleanUser();
    const updateProjectPath = generatePath(PROJECT_SETTINGS_EDIT_PROJECT, { projectId });
    navigate(updateProjectPath);
  };
  const handelDisplayName = (u) => {
    if (u.firstName && u.lastName) {
      return { ...u, fullName: `${u.firstName} ${u.lastName}` };
    }
    return { ...u, fullName: u.mail };
  };

  const handleOpenDraftModal = () => {
    setIsDraft(true);
    setOpenDraftModal(true);
  };

  const handleOpenModal = () => {
    setShowCancelPopup(true);
  };

  const handleSave = (saveLeaveValue) => {
    setIsDraft(false);
    setSaveLeave(saveLeaveValue);
    onClickCreateUpdate();
  };

  useEffect(() => {
    if (creationSuccess) {
      let redirectPath;
      if (!saveLeave && !isDraft) {
        redirectPath = generatePath(PROJECT_SETTINGS_EDIT_PROJECT_WORK_DIMENSIONS, {
          projectId,
        });
        if (!isNavigation) {
          navigate(redirectPath);
        }
        setIsNavigation(false);
      } else {
        setShowSuccessPopup(true);
      }
    }
  }, [creationSuccess]);

  const platformsProfiles = () => {
    const platformDetails = platforms?.find((p) => p?.code === platform);
    return (
      <Stack className="formWidth" key={platformDetails?.name}>
        <AutocompleteInput
          isWithCheckbox={false}
          label={`${platformDetails?.name} profiles`}
          handleOnInputChange={(_, value, reason) => {
            if (reason === 'input') {
              setProfileName(value);
              if (value === '') {
                setFilteredProfiles(profiles);
              }
            }
          }}
          placeholder="Project Profile"
          handleOnChange={(_, values) => {
            selectedProfile(values[0]);
            setProfileName(values[0]?.name);
          }}
          inputValue={profileName}
          options={filteredProfiles}
          valueKey="name"
        />
      </Stack>
    );
  };

  const platformsTab = () => {
    return platforms.reduce((acc, item) => {
      acc[item.name] = {
        label: item.name,
        code: item.code,
      };
      return acc;
    }, {});
  };
  const hasWritePrivilege = usePrivilegeByFeature(
    PLATFORM_FEATURE.get(platform),
    Privileges.WRITE,
  );
  return (
    <ThemeProvider theme={AppTheme}>
      {(filterLoading || rolesLoading || isloadingProfiles) && <FullPageLoader />}
      <ProjectsSettingsProjectMembersWrapper>
        <Stack className="top-header">
          <Stack className="heading-area">
            <BackButton
              onClick={() => {
                if (!dataUpdated) {
                  navigateToProjectCreation();
                } else {
                  setConfirmationBackPopup(true);
                }
              }}
              type="button"
            >
              <ArrowLeftIcon />
            </BackButton>
            <Stack className="page-title-area">
              <Typography variant="h3_HelveticaNeue_Bold" color="primary.main">
                Project member
              </Typography>
            </Stack>
          </Stack>

          <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.main">
            Add project member to a project
          </Typography>
        </Stack>

        <Stack className="projectMembersContainer">
          <Stack className="form-search-area-container">
            <Stack className="form-search-area">
              <Typography variant="h6_HelveticaNeue_Bold" color="text.main">
                Add member to project
              </Typography>
            </Stack>
            <TabTitles
              className="mb-40"
              activeTab={platform || platforms[0]?.code}
              tabs={platformsTab()}
              handleTabChange={(tab) => {
                if (dataUpdated) {
                  setTempPlatform(tab);
                  setIsNavigation(true);
                  setOpenPlatformModal(true);
                } else {
                  cleanUser();
                  setPlatform(tab);
                }
              }}
            />
            {hasWritePrivilege && (
              <Stack>
                <Stack className="flex_wrap">
                  <Stack className="formWidth">
                    <AutocompleteInput
                      className={isSelectedUser ? 'hide' : ''}
                      isWithCheckbox={false}
                      label="Search members"
                      handleOnInputChange={(_, value, reason) => {
                        if (reason === 'input') {
                          setFullName(value);
                        }
                      }}
                      handleOnChange={(_, values) => {
                        if (values[0]?.mail === null) {
                          setNullMailErrorMessage(
                            'It is forbidden to associate a member to a project with an empty email.',
                          );
                        } else {
                          dispatch(setSelectedUser(values[0]));
                          setFullName(values[0]?.fullName);
                          setIsSelectedUsed(true);
                          setNullMailErrorMessage(null);
                        }
                      }}
                      inputValue={fullName}
                      placeholder="Type 3 characters to search..."
                      options={users?.map((u) => handelDisplayName(u))}
                      valueKey="fullName"
                      error={associate && !fullName}
                    />
                    <Stack className={isSelectedUser ? '' : 'hide'}>
                      <ProjectsSettingsProjectMembersItem
                        classname="searchedUser"
                        userProject={{ user: selectedUser }}
                        key={selectedUser?.id}
                        onDelete={() => {
                          cleanUser();
                        }}
                        email={selectedUser?.mail}
                        hideRole
                      />
                    </Stack>
                  </Stack>
                  <AutocompleteInput
                    className="formWidth"
                    isWithCheckbox={false}
                    label="Project Role"
                    handleOnInputChange={(_, value, reason) => {
                      if (reason === 'input') {
                        setRoleName(value);
                        if (value === '') {
                          setFilteredRoles(roles);
                        }
                      }
                    }}
                    placeholder="Project Role"
                    handleOnChange={(_, values) => {
                      dispatch(setSelectedRole(values[0]));
                      setRoleName(values[0]?.label);
                    }}
                    inputValue={roleName}
                    options={filteredRoles?.length !== 0 ? filteredRoles : roles}
                    valueKey="label"
                  />
                  {Object.keys(selectedRole)?.length > 0 && platformsProfiles()}
                  <ActionButton
                    classname="footer--next__button formWidth associate_size"
                    startIcon={<LinkSvg />}
                    label="Associate"
                    onClick={handleAssociateMember}
                  />
                  {nullMailErrorMessage && (
                    <FormHelperText className="errorMsg">
                      {nullMailErrorMessage}
                    </FormHelperText>
                  )}
                </Stack>
              </Stack>
            )}
            <Divider className="divider" />

            <Stack className="flex__row--center navigations-tabs">
              <Stack
                className={`navigations-tabs-item ${
                  selected === TAB_FILTER_NAME.ALL.code && 'active'
                }`}
                onClick={() => setSelected(TAB_FILTER_NAME.ALL.code)}
              >
                <Typography variant="subtitle1_HelveticaNeue_Bold" color="primary.main">
                  {TAB_FILTER_NAME.ALL.label}
                </Typography>
              </Stack>
              <Stack
                className={`navigations-tabs-item ${
                  selected === TAB_FILTER_NAME.JESA.code && 'active'
                }`}
                onClick={() => setSelected(TAB_FILTER_NAME.JESA.code)}
              >
                <Typography variant="subtitle1_HelveticaNeue_Bold" color="primary.main">
                  {TAB_FILTER_NAME.JESA.label}
                </Typography>
              </Stack>
              <Stack
                className={`navigations-tabs-item ${
                  selected === TAB_FILTER_NAME.EXTERNAL_CLIENT.code && 'active'
                }`}
                onClick={() => setSelected(TAB_FILTER_NAME.EXTERNAL_CLIENT.code)}
              >
                <Typography variant="subtitle1_HelveticaNeue_Bold" color="primary.main">
                  {TAB_FILTER_NAME.EXTERNAL_CLIENT.label}
                </Typography>
              </Stack>
              <Stack
                className={`navigations-tabs-item ${
                  selected === TAB_FILTER_NAME.EXTERNAL_CONTRACTOR.code && 'active'
                }`}
                onClick={() => setSelected(TAB_FILTER_NAME.EXTERNAL_CONTRACTOR.code)}
              >
                <Typography variant="subtitle1_HelveticaNeue_Bold" color="primary.main">
                  {TAB_FILTER_NAME.EXTERNAL_CONTRACTOR.label}
                </Typography>
              </Stack>
            </Stack>

            <Stack className="list__members-container">
              <Stack className="list__members-heading">
                <Typography variant="h6_HelveticaNeue_Bold" color="text.main">
                  Members
                </Typography>
              </Stack>

              <Stack className="list__members">
                {userProjectsByCategory
                  ?.filter((up) => !up.deleted && !up.profiles[0]?.deleted)
                  .map((userProject) => (
                    <ProjectsSettingsProjectMembersItem
                      userProject={userProject}
                      key={userProject?.user?.id}
                      onDelete={onDelete}
                      onClick={onclick}
                      hideDelete={!hasWritePrivilege}
                    />
                  ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <ProjectSettingsFooter
          cancelLabel="Cancel"
          cancelOnClick={handleOpenModal}
          DraftLabel="Save as Draft"
          DraftOnClick={handleOpenDraftModal}
          saveLeaveLabel="Save"
          showSaveLeaveBtn={projectId}
          saveLabel="Save & continue"
          saveOnClick={handleSave}
          showDraftBtn={projectCreationStatus === PROJECT_CREATION_STATUS.DRAFT}
        />
        {showCancelPopup && (
          <ConfirmationPopup
            width="500px"
            toOpen={showCancelPopup}
            popupType={POPUP_TYPE.CONFIRMATION_CANCEL__POPUP}
            onConfirm={onCancelClick}
            onCancel={() => setShowCancelPopup(false)}
            onClose={() => setShowCancelPopup(false)}
          />
        )}
        {openDraftModal && (
          <ProjectCreationPopup
            projectId={projectId}
            toOpen={openDraftModal}
            onConfirm={onClickCreateUpdate}
            saveSuccess={creationSuccess}
            saveLoading={creationLoading}
            setOpenDraftModal={setOpenDraftModal}
            setShowSuccessPopup={setShowSuccessPopup}
          />
        )}
        <ConfirmationPopup
          toOpen={dataUpdated && confirmationBackPopup}
          popupType={POPUP_TYPE.CONFIRMATION_BACK__POPUP}
          onConfirm={() => {
            onClickCreateUpdate();
            navigateToProjectCreation();
          }}
          onCancel={() => setConfirmationBackPopup(false)}
          onClose={() => setConfirmationBackPopup(false)}
        />
        {showSuccessPopup && (
          <ConfirmationPopup
            toOpen={showSuccessPopup}
            popupType={POPUP_TYPE.SUCCESS__POPUP}
            onCancel={onCancelSuccess}
            onClose={onCancelSuccess}
          />
        )}
        {openPlatformModal && (
          <ConfirmationPopup
            toOpen={openPlatformModal}
            entityName="platform"
            popupType={POPUP_TYPE.CONFIRMATION_TAB__POPUP}
            onConfirm={() => {
              onClickCreateUpdate();
              setPlatform(tempPlatform);
              setOpenPlatformModal(false);
              setDataUpdated(false);
            }}
            onCancel={() => {
              setTempPlatform('');
              setOpenPlatformModal(false);
            }}
            onClose={() => {
              setTempPlatform('');
              setOpenPlatformModal(false);
            }}
          />
        )}
      </ProjectsSettingsProjectMembersWrapper>
    </ThemeProvider>
  );
}

export default ProjectsSettingsProjectMembers;
